import React from 'react'

const Contact = () => {
  return (
    <section id="main" className="container medium">
      <section class="box special">
        <header>
          <h2>Contactenos</h2>
        </header>
        <div className="box">
          <form method="post" action="#">
            <div className="row gtr-50 gtr-uniform">
              <div className="col-6 col-12-mobilep">
                <input
                  type="text"
                  name="name"
                  id="name"
                  value=""
                  placeholder="Nombre"
                />
              </div>
              <div className="col-6 col-12-mobilep">
                <input
                  type="email"
                  name="email"
                  id="email"
                  value=""
                  placeholder="Email"
                />
              </div>
              <div className="col-12">
                <input
                  type="text"
                  name="subject"
                  id="subject"
                  value=""
                  placeholder="Asunto"
                />
              </div>
              <div className="col-12">
                <textarea
                  name="message"
                  id="message"
                  placeholder="Ingrese su mensaje"
                  rows="6"
                ></textarea>
              </div>
              <div className="col-12">
                <ul className="actions special">
                  <li>
                    <input type="submit" value="Enviar Mensaje" />
                  </li>
                </ul>
              </div>
            </div>
          </form>
        </div>
      </section>
    </section>
  );
}

export default Contact