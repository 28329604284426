import React from 'react'

const About = () => {
  return (
    <section id="main" class="container">
      <section class="box special">
        <header>
          <h2>A y C C</h2>
          <p>Conoce nuestros proyectos.</p>
        </header>
        <div class="box">
          <span class="image featured">
            <img src="images/pic01.jpg" alt="" />
          </span>
          <h3>Jeremias 17:5</h3>
          <p>
            Hey Con la verdad se llega lejos Súbela, súbela mijo ahí, un pelín
            Hey, en ninguna "Cuídese de la envidia, mijo" Mirándome a los ojos,
            mi vieja, descanse en paz, ya una vez me dijo Y que razón tenía al
            advertirme de esta vida puerca No críes cuervos, o arrancaran tus
          </p>
          <div class="row">
            <div class="row-6 row-12-mobilep">
              <h3>And now a subheading</h3>
              <p>
                Adipiscing faucibus nunc placerat. Tempus adipiscing turpis non
                blandit accumsan eget lacinia nunc integer interdum amet aliquam
                ut orci non col ut ut praesent. Semper amet interdum mi.
                Phasellus enim laoreet ac ac commodo faucibus faucibus. Curae
                lorem ipsum adipiscing ac. Vivamus ornare laoreet odio vis
                praesent.
              </p>
            </div>
            <div class="row-6 row-12-mobilep">
              <h3>And another subheading</h3>
              <p>
                Adipiscing faucibus nunc placerat. Tempus adipiscing turpis non
                blandit accumsan eget lacinia nunc integer interdum amet aliquam
                ut orci non col ut ut praesent. Semper amet interdum mi.
                Phasellus enim laoreet ac ac commodo faucibus faucibus. Curae
                lorem ipsum adipiscing ac. Vivamus ornare laoreet odio vis
                praesent.
              </p>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}

export default About