import React from 'react'

const Navbar = () => {
  return (
    <>
      <header id="header" className="alt">
        <h2 style={{fontFamily: 'Constantia', color:'white', paddingLeft:'10px', paddingTop:'7px', fontSize: '17px'}}>
          <img src="images/AyCCv2-microchikito.png"></img> Quilmes, Argentina
        </h2>
        <nav id="nav" style={{fontFamily: 'Constantia'}}>
          <ul>
            <li>
              <a href="/">Inicio</a>
            </li>
            <li>
              <a href="/us">Nosotros</a>
            </li>
            <li>
              <a href="/contacto">Contactenos</a>
            </li>
          </ul>
        </nav>
      </header>
      <section id="banner" style={{fontFamily: 'Constantia', fontSize: "18px"}}>
        <h2><img src= "images/AyCCv2-maschikito.png" class="img-fluid" alt="Responsive image" style={{margin:'0px'}}></img></h2>
        <p>Otra forma de crear, otra forma de Construir</p>
      </section>
    </>
  );
}

export default Navbar