import React from 'react';


const Section = () => {
  return (
    <section id="main" className="container">
      <section className="box special">
        <header className="major">
          
          <h2>
            Introducing the ultimate mobile app
            <br />
            for doing stuff with your phone
          </h2>
          <p>
            Blandit varius ut praesent nascetur eu penatibus nisi risus faucibus
            nunc ornare
            <br />
            adipiscing nunc adipiscing. Condimentum turpis massa.
          </p>
        </header>
        <span className="image featured">
          <img src="images/pic011.jpg" alt="" />
        </span>
      </section>

      <section className="box special features">
        <div className="features-row">
          <section>
            <span className="icon solid major fa-bolt accent2"></span>
            <h3>Magna etiam</h3>
            <p>
              Integer volutpat ante et accumsan commophasellus sed aliquam
              feugiat lorem aliquet ut enim rutrum phasellus iaculis accumsan
              dolore magna aliquam veroeros.
            </p>
          </section>
          <section>
            <span className="icon solid major fa-chart-area accent3"></span>
            <h3>Ipsum dolor</h3>
            <p>
              Integer volutpat ante et accumsan commophasellus sed aliquam
              feugiat lorem aliquet ut enim rutrum phasellus iaculis accumsan
              dolore magna aliquam veroeros.
            </p>
          </section>
        </div>
        <div className="features-row">
          <section>
            <span className="icon solid major fa-cloud accent4"></span>
            <h3>Sed feugiat</h3>
            <p>
              Integer volutpat ante et accumsan commophasellus sed aliquam
              feugiat lorem aliquet ut enim rutrum phasellus iaculis accumsan
              dolore magna aliquam veroeros.
            </p>
          </section>
          <section>
            <span className="icon solid major fa-lock accent5"></span>
            <h3>Enim phasellus</h3>
            <p>
              Integer volutpat ante et accumsan commophasellus sed aliquam
              feugiat lorem aliquet ut enim rutrum phasellus iaculis accumsan
              dolore magna aliquam veroeros.
            </p>
          </section>
        </div>
      </section>
    </section>
  );
}

export default Section