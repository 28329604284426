import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import About from "./components/About";
import Contact from './components/Contact';
import Footer from "./components/Footer";
import Navbar from './components/Navbar';
import Section from './components/Section';

function App() {
  return (
    <body className="landing is-preload">
      <div id="page-wrapper">
        <Navbar />
        <Router>
          <Routes>
            <Route element={<Section />} path="/" />
            <Route element={<Contact />} path="/contacto" />
            <Route element={<About />} path="/us" />
          </Routes>
        </Router>
        <Footer />
      </div>
    </body>
  );
}

export default App;
