import React from 'react'

const Footer = () => {
  return (
    <footer id="footer">
      <ul class="icons">
        <li>
          <a href="#" class="icon brands fa-twitter">
            <span class="label">Twitter</span>
          </a>
        </li>
        <li>
          <a href="#" class="icon brands fa-facebook-f">
            <span class="label">Facebook</span>
          </a>
        </li>
        <li>
          <a href="#" class="icon brands fa-instagram">
            <span class="label">Instagram</span>
          </a>
        </li>       
        <li>
          <a href="#" class="icon brands fa-google-plus">
            <span class="label">Google+</span>
          </a>
        </li>
      </ul>
      <ul class="copyright">
        <li>&copy; AyCC. Derechos Reservados.</li>
        <li>
          Diseñador: <a href="https://www.linkedin.com/in/marcio-liendro-ratto-118683149/">Liendro Ratto Marcio</a>
        </li>
      </ul>
    </footer>
  );
}

export default Footer